.kiosk__back-button--reset {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    text-align: inherit;
    vertical-align: baseline;
    -webkit-print-color-adjust: exact;
    -webkit-font-smoothing: antialiased;
}

.kiosk__back-button {
    @extend .kiosk__back-button--reset;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 20px;
    left: 20px;
    text-align: center;
    text-decoration: none;
    z-index: 999;
    transition: opacity 0.3s ease;
    background: var(--site-colour-primary);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    .kiosk__back-button-circle {
        @extend .kiosk__back-button--reset;
        width: 54px;
        height: 54px;
        background-color: white;
        color: var(--site-colour-primary);
        font-size: 24px;
        font-weight: 500;
        border-radius: 50%;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        transition: background-color 0.3s ease;
    }

    span {
        @extend .kiosk__back-button--reset;
        font-family: 'Gotham Pro', Verdana, sans-serif !important;
        color: white;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.7142857143;
    }
}
